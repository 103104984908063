import React from "react"
import { Link } from "gatsby"

import { Button, Row, Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/style.scss"

const Success = () => (
  <Layout pageInfo={{ pageName: "success" }}>
    <SEO
      title="Message success"
      keywords={[`green`, `velvet`, `concierge`, `telluride`, `wedding`]}
    />

    <Container className="main-container">
      <Row className="justify-content-center my-3" id="play-headline">
        <Col>Hi from Green Velvet!</Col>
      </Row>
      <Row className="justify-content-center my-3">
        <Col className="content">
          <strong>Your message has been submitted.</strong>
        </Col>
      </Row>
      <Row className="justify-content-center my-3">
        <Col className="content">
          Thank you for reaching out. We will respond as soon as we are able.
          Have a great day!
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button
              className="btn btn-block to-contact"
              type="submit"
              variant="success"
            >
              Back to the home page
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Success
